/* Reset básico */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background-color: #000; /* Fundo preto */
  color: #fff; /* Texto branco */
  line-height: 1.5;
  padding: 10px;
}

/* Container principal */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  gap: 20px;
}

/* Título */
h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

/* Imagem */
.image-container img {
  width: 100%; /* Faz a imagem ocupar toda a largura do contêiner */
  max-width: 1200px; /* Define um tamanho máximo para a imagem */
  height: auto; /* Mantém a proporção da imagem */
  display: block; /* Remove o espaço extra ao redor da imagem */
  margin: 0 auto; /* Centraliza a imagem horizontalmente */
  border-radius: 10px; /* Bordas arredondadas (opcional) */
}


/* Formulário */
.form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  max-width: 400px;
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

label {
  font-size: 1rem;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 5px;
  background-color: #222; /* Fundo dos campos de entrada */
  color: #fff;
  font-size: 1rem;
}

input::placeholder {
  color: #aaa; /* Cor do placeholder */
}

/* Botão */
.submit-button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  background-color: #1a73e8; /* Azul vibrante */
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #1358a5; /* Azul mais escuro ao passar o mouse */
}

/* Responsividade */
@media (max-width: 768px) {
  h1 {
    font-size: 1.5rem;
  }

  .submit-button {
    font-size: 0.9rem;
  }
}
